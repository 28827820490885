import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { KeyValue } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { BibliaInfoBook } from '../../../../services/biblia-info/biblia-info-book';
import { BOOKS_NAMES_1, BOOKS_NAMES_2, BOOKS_NAMES_3 } from '../../../../services/biblia-info/available-books';
import { BreakpointsService } from '../../../../services/breakpoints.service';
import { DisableBibleQueryComponent } from '../../../../components/utils/bible-dialog/disable-bible-query.component';
import { StateService } from '../../../../services/state/state.service';

export interface BibleBookInfoDialogData {
  book: BibliaInfoBook;
  chapter: number;
  onlyGreek?: boolean;
}

export interface BibleBookInfoDialogResult {
  book: BibliaInfoBook;
  chapter: number;
}

@Component({
  templateUrl: './bible-book-info-dialog.component.html',
  styleUrls: ['./bible-book-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BibleBookInfoDialogComponent extends DisableBibleQueryComponent implements OnInit {
  protected groupedBooks = [BOOKS_NAMES_2, BOOKS_NAMES_1, BOOKS_NAMES_3];

  protected selectedBook: BibliaInfoBook;

  @HostBinding('class.mobile-view-simple') protected isMobileViewSimple = false;

  @HostBinding('class.current-book') private currentBook = false;

  @ViewChild('container', { static: false }) private container: ElementRef<HTMLDivElement>;

  constructor(@Inject(MAT_DIALOG_DATA) protected data: BibleBookInfoDialogData,
              protected dialogRef: MatDialogRef<BibleBookInfoDialogComponent, BibleBookInfoDialogResult>,
              private breakpointsService: BreakpointsService,
              private stateService: StateService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isMobileViewSimple = this.breakpointsService.isPortraitLandscape ? this.stateService.mobileNavSimple : false;
    this.groupedBooks = this.data.onlyGreek ? [BOOKS_NAMES_2] : [BOOKS_NAMES_2, BOOKS_NAMES_1, BOOKS_NAMES_3];
  }

  protected defaultOrder(a: KeyValue<BibliaInfoBook, string>, b: KeyValue<BibliaInfoBook, string>): number {
    return 0;
  }

  protected selectBook(book: BibliaInfoBook): void {
    this.selectedBook = book;
    this.currentBook = book === this.data.book;
    this.container.nativeElement.scrollTo(0, 0);
  }

  protected toggleView($event: MatCheckboxChange): void {
    this.stateService.setMobileNavSimple($event.checked);
  }
}
