/* src/app/pages/common/bible/filter/bible-filter/bible-filter.component.scss */
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden {
    display: none !important;
  }
}
.center.right {
  display: flex;
  justify-content: flex-end;
}
.center.block {
  display: block;
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
.image-box {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  border: 1px solid var(--color-disable);
}
.hover {
  cursor: pointer;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:host .form-container {
  display: flex;
  margin-top: 3rem;
  position: relative;
}
:host .form-container .mat-mdc-form-field {
  margin-right: 2rem;
}
@media only screen and (min-width: 1024px) {
  :host .form-container .mat-mdc-form-field.field-bible {
    width: 30%;
  }
  :host .form-container .mat-mdc-form-field.field-book {
    width: 25%;
  }
  :host .form-container .mat-mdc-form-field.field-chapter {
    width: 15%;
  }
  :host .form-container .mat-mdc-form-field.field-bible-compare {
    width: 30%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .form-container .mat-mdc-form-field {
    width: 50%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .form-container .mat-mdc-form-field.field-bible {
    margin-right: 0;
  }
  :host .form-container .mat-mdc-form-field.field-book {
    width: 70%;
  }
  :host .form-container .mat-mdc-form-field.field-chapter {
    width: 30%;
    margin-right: 0;
  }
  :host .form-container .mat-mdc-form-field.field-bible-compare {
    margin-right: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .form-container .mat-mdc-form-field {
    width: 100%;
    margin-right: 0;
  }
}
@media only screen and (max-width: 1114px) {
  :host .form-container.desktop-and-portrait {
    display: none;
  }
}
:host .form-container.desktop-and-portrait .field-bible-compare {
  margin-right: 0;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .form-container.desktop-and-portrait {
    flex-direction: column;
  }
  :host .form-container.desktop-and-portrait .field-chapter {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .form-container.desktop-and-portrait {
    display: block;
  }
  :host .form-container.desktop-and-portrait .flex-container {
    justify-content: center;
  }
  :host .form-container.desktop-and-portrait .flex-container .field-chapter {
    margin: 0;
  }
  :host .form-container.desktop-and-portrait .mat-expansion-panel {
    border-radius: unset;
    background-color: transparent;
    box-shadow: none;
    color: var(--color-text);
    border: 2px solid var(--color-brand);
    margin-bottom: 1rem;
  }
  :host .form-container.desktop-and-portrait .mat-expansion-panel:not(.mat-expanded) {
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  :host .form-container.desktop-and-portrait .mat-expansion-panel .mat-expansion-panel-header {
    height: 4rem;
    transition: 600ms cubic-bezier(0.79, 0, 0.22, 1);
    background-color: var(--color-background-dark);
    border-color: rgba(var(--color-background-dark-rgb), 0.8);
  }
  :host .form-container.desktop-and-portrait .mat-expansion-panel .mat-expansion-panel-header.mat-expanded {
    margin-bottom: 1rem;
    background: rgba(var(--color-background-dark-rgb), 0.6);
    border-bottom: 1px solid rgba(var(--color-background-dark-rgb), 0.8);
  }
  :host .form-container.desktop-and-portrait .mat-expansion-panel .mat-expansion-panel-header:hover .mat-expansion-panel-header-title {
    color: var(--color-text-hover);
  }
  :host .form-container.desktop-and-portrait .mat-expansion-panel .mat-expansion-panel-header:hover ::ng-deep .mat-expansion-indicator::after {
    color: var(--color-text-hover);
  }
  :host .form-container.desktop-and-portrait .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
    color: var(--color-text);
  }
  :host .form-container.desktop-and-portrait .mat-expansion-panel ::ng-deep .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 0 0.5rem;
  }
}
:host .form-container.desktop-small-and-tablet {
  display: block;
  width: inherit;
}
@media only screen and (max-width: 767px) {
  :host .form-container.desktop-small-and-tablet {
    display: none;
  }
}
@media only screen and (min-width: 1115px) {
  :host .form-container.desktop-small-and-tablet {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  :host .form-container.desktop-small-and-tablet .mat-mdc-form-field.field-bible {
    width: 50%;
  }
  :host .form-container.desktop-small-and-tablet .mat-mdc-form-field.field-book {
    width: 50%;
  }
  :host .form-container.desktop-small-and-tablet .mat-mdc-form-field.field-chapter {
    width: 30%;
  }
  :host .form-container.desktop-small-and-tablet .mat-mdc-form-field.field-bible-compare {
    width: 70%;
  }
}
:host .form-container.desktop-small-and-tablet .field-book {
  margin-right: 0;
}
:host .form-container.desktop-small-and-tablet .field-bible-compare {
  margin-right: 0;
}
:host .form-container.landscape {
  flex-direction: column;
}
@media only screen and (max-width: 479px) {
  :host .form-container.landscape {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  :host .form-container.landscape {
    display: none;
  }
}
:host .flex-container {
  display: flex;
}
.mat-mdc-option .copyright-info {
  position: absolute;
  right: 0;
}
/*# sourceMappingURL=bible-filter.component.css.map */
