import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit, ViewChild, } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ViewportScroller } from '@angular/common';

import { catchError, map, tap } from 'rxjs/operators';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';

import { ActiveRouteService } from '../../../services/active-route.service';
import { AVAILABLE_BIBLES_CODES } from '../../../services/biblia-info/available-codes';
import { AVAILABLE_BIBLES, BOOKS_NAMES, BOOKS_NAMES_1, BOOKS_NAMES_2, BOOKS_NAMES_3 } from '../../../services/biblia-info/available-books';
import {
  BibleBookInfoDialogComponent,
  BibleBookInfoDialogData,
  BibleBookInfoDialogResult
} from './bible-book-info-dialog/bible-book-info-dialog.component';
import {
  BibleFavouritesMobileDialogComponent, BibleFavouritesMobileDialogData,
  BibleFavouritesMobileDialogResult, BibleFavouritesMobileDialogResultType
} from './side-tools/bible-favourites-mobile-dialog.component';
import { BibleFilterComponent } from './filter/bible-filter/bible-filter.component';
import { BibleForm, BibleGroup, BibleItem, BibleQueryParams, BibleStorage, BibleTranslation } from './bible.model';
import {
  BibliaInfoBibleModel,
  BibliaInfoChapterModel,
  BibliaInfoErrorModel,
  BibliaInfoTranslationModel,
  BibliaInfoVerseModel
} from '../../../services/biblia-info/biblia-info.model';
import { BibliaInfoService } from '../../../services/biblia-info/biblia-info.service';
import { BibliaInfoBook } from '../../../services/biblia-info/biblia-info-book';
import { BibliaInfoCode, CustomBibleCode } from '../../../services/biblia-info/biblia-info-code';
import { BibleQueryResult } from './bible-search-query/bible-search-query-dialog/bible-search-query-dialog.component';
import { BOOKS_CHAPTERS } from '../../../services/biblia-info/books-chapters';
import { ChapterChange, ChapterChangeResult } from './chapter-button/chapter-button.component';
import { CompareVerseDialogComponent } from './compare-verse-dialog/compare-verse-dialog.component';
import { CopyVersesDialogData, CopyVersesDialogComponent } from './content/copy-verses-dialog/copy-verses-dialog.component';
import { FavouriteChapter } from '../../../services/left-panel/favourite-chapter.model';
import { getUbgBibliaInfoChapter, ubgTranslation } from '../../../services/biblia-info/bible-custom-translations';
import { LeftPanelService } from '../../../services/left-panel/left-panel.service';
import { MenuService } from '../../../services/menu.service';
import {
  SelectBibleTranslationsDialogComponent,
  SelectBibleTranslationsDialogData,
} from './bible-actions/select-bible-translations-dialog/select-bible-translations-dialog.component';
import { SelectChapterEvent } from './left-panel/bible-left-panel.component';
import { SnackbarService } from '../../../components/snackbar/snackbar.service';
import { SnackBarType } from '../../../components/snackbar/snackbar-type.enum';
import { StateService } from '../../../services/state/state.service';
import { StrongService } from '../../../services/strong/strong.service';
import { SubComponent } from '../../../components/utils/sub/sub.component';
import { VersesListDialogService } from '../../../services/verses-list-dialog.service';
import { VerseUbg } from '../../../services/strong/strong.model';

@Component({
  selector: 'app-bible-component',
  templateUrl: './bible.component.html',
  styleUrls: ['./bible.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LeftPanelService, SnackbarService],
})
export class BibleComponent extends SubComponent implements OnInit {
  static readonly bibleLanguageNames = new Map([
    ['pl', 'język polski'],
    ['en', 'język angielski'],
    ['gr', 'język grecki'],
    ['la', 'język łaciński'],
  ]);

  static readonly bookPartNames = new Map([
    ['st', 'Stare Przymierze'],
    ['nt', 'Nowe Przymierze'],
    ['apo', 'apokryfy'],
  ]);

  protected form: FormGroup<BibleForm>;
  private prevFormState: FormGroup<BibleForm>;

  protected bibleGroup: BibleGroup[];
  private bibles: BibliaInfoBibleModel[];
  protected bookGroup: BibleGroup[];
  protected books: BibleItem[];
  protected chapterItem: BibliaInfoChapterModel;
  protected compareChapterItems: Map<BibliaInfoCode, BibliaInfoChapterModel>;
  protected chapters: number[];
  protected description: string;
  protected favouriteChapters: FavouriteChapter[];
  protected lastChapters: FavouriteChapter[];
  protected fontSize: number;
  protected maxCompareColumns: number;
  private selectedTranslations: BibliaInfoBibleModel[];
  protected versesUbg: VerseUbg[];

  protected isLoading = true;
  protected collapseAsideFilter = false;
  protected over1700px = false;
  protected hidePrevChapterButton = true;
  protected hideNextChapterButton = true;
  protected isSideNavOpen = false;
  protected isFavouriteChapter = false;
  protected stopAnimationOnHideButtonChange = false;

  protected hideLeftPanel: boolean;
  protected versesInNewLine: boolean;

  private skipFetchingDataAndScrollToVerse = false;

  @HostBinding('class.full-screen') protected fullScreen = false;
  @HostBinding('class.hide-right-panel') private hideRightPanel: boolean;

  @ViewChild(BibleFilterComponent, { static: true }) private readonly bibleFilterComponent: BibleFilterComponent;

  private openBibleBookDialogRef: MatDialogRef<BibleBookInfoDialogComponent, BibleBookInfoDialogResult>;

  private omitDataUpdateOnQueryParamsChange: boolean;

  private get bible(): BibliaInfoCode { return this.form.get('bible').value as BibliaInfoCode; }
  private get book(): BibliaInfoBook { return this.form.get('book').value as BibliaInfoBook; }
  private get chapter(): number { return this.form.get('chapter').value; }
  private get bibleCompare(): BibliaInfoCode[] { return this.form.get('bibleCompare').value as BibliaInfoCode[] || []; }

  constructor(private activatedRoute: ActivatedRoute,
              private activeRouteService: ActiveRouteService,
              private bibliaInfo: BibliaInfoService,
              private breakpointObserver: BreakpointObserver,
              private cdr: ChangeDetectorRef,
              private clipboard: Clipboard,
              private dialog: MatDialog,
              private leftPanelService: LeftPanelService,
              private fb: FormBuilder,
              private menuService: MenuService,
              private router: Router,
              private snackbarService: SnackbarService,
              private stateService: StateService,
              private strongService: StrongService,
              private viewportScroller: ViewportScroller,
              private versesListDialogService: VersesListDialogService) {
    super();
  }

  ngOnInit(): void {
    this.fetchBooks();
    this.activeRouteService.changeIndexes(0, null);
    this.fullScreenMode(this.stateService.fullScreen);
    this.observeBreakpointsChanges();
    this.observeToggle();
    this.observeShowLeftPanel();
    this.observeShowRightPanel();
    this.observeScreenMode();
    this.initFormFromStorage();
    this.observeFavouriteChapter();
    this.observeLastChapter();
    this.observeQueryParams();
    this.observeVersesList();
  }

  bibleSelectionChange(form: FormGroup<BibleForm>): void {
    this.omitDataUpdateOnQueryParamsChange = true;
    this.isLoading = true;
    this.updateForm(form);
    this.validateCompareBible(form.get('bible').value as BibliaInfoCode);
    this.description = undefined;
    this.scrollToTop();
    this.fetchTranslation();
  }

  bookSelectionChange(form: FormGroup<BibleForm>, verse?: number): void {
    this.omitDataUpdateOnQueryParamsChange = true;
    this.isLoading = true;
    this.updateForm(form);
    this.scrollToTop();
    this.updateInvalidChapter();
    this.fetchBook(verse);
  }

  chapterSelectionChange(form: FormGroup<BibleForm>): void {
    this.omitDataUpdateOnQueryParamsChange = true;
    this.isLoading = true;
    this.updateForm(form);
    this.scrollToTop();
    this.updateInvalidBible();
    this.fetchChapter().subscribe();
    this.getCompareChapters();
  }

  bibleCompareSelectionChange(form: FormGroup<BibleForm>, skipScrollToTop = false) {
    this.updateForm(form);

    if (this.bibleCompare) {
      if (!skipScrollToTop) {
        this.scrollToTop();
      }
      this.getCompareChapters();
    } else {
      this.compareChapterItems = undefined;
      this.saveBibleInStorage();
      this.cdr.markForCheck();
    }
  }

  chapterButtonChange(result: ChapterChangeResult): void {
    if (result.openInBlankPage) {
      const chapterItem = this.getFavouriteChapter();
      chapterItem.chapter += result.change === ChapterChange.PREV ? -1 : 1;
      this.openBlankPageWithQueryParams(null, chapterItem);
      return;
    }
    this.isLoading = true;
    if (result.change === ChapterChange.PREV) {
      this.form.get('chapter').setValue(this.chapter - 1);
      this.hidePrevChapterButton = this.chapter <= 1;
      this.hideNextChapterButton = false;
      this.stopAnimationOnHideButtonChange = true;
      setTimeout(() => this.stopAnimationOnHideButtonChange = false, 800);
      this.fetchChapter().subscribe();
      this.getCompareChapters();
    } else {
      this.form.get('chapter').setValue(this.chapter + 1);
      this.hideNextChapterButton = this.chapter >= this.chapters.length;
      this.hidePrevChapterButton = false;
      this.stopAnimationOnHideButtonChange = true;
      setTimeout(() => this.stopAnimationOnHideButtonChange = false, 800);
      this.fetchChapter().subscribe();
      this.getCompareChapters();
    }
  }

  favouriteChapterChange(value: boolean): void {
    const favouriteChapter = this.getFavouriteChapter();

    if (value) {
      this.leftPanelService.setFavouriteChapter(favouriteChapter);
    } else {
      this.leftPanelService.removeFavouriteChapter(favouriteChapter);
    }
    this.updateFavouriteChapter();
  }

  leftPanelSelectChapter(favouriteChapter: (SelectChapterEvent | FavouriteChapter), navigateToVerse = false): void {
    if (BibleComponent.isSelectChapterEvent(favouriteChapter)) {
      this.leftPanelFetchChapter(favouriteChapter.selectChapter, navigateToVerse, favouriteChapter.openInBlankPage);
    } else {
      this.leftPanelFetchChapter(favouriteChapter, navigateToVerse);
    }
  }

  private leftPanelFetchChapter(favouriteChapter: FavouriteChapter, navigateToVerse = false, openInBlankPage?: boolean): void {
    if (this.isCurrentChapter(favouriteChapter)) {
      if (openInBlankPage) {
        this.openBlankPageWithQueryParams(favouriteChapter?.verse, favouriteChapter);
      } else {
        this.navigateToCurrentChapter(favouriteChapter);
      }
      return;
    }
    if (!openInBlankPage) {
      this.isLoading = true;
      this.updateFormByFavouriteChapter(favouriteChapter);
      this.fetchChapter().subscribe(() => {
        if (navigateToVerse) {
          this.navigateToCurrentChapter(favouriteChapter, openInBlankPage);
        } else {
          this.updateQueryParams(null, openInBlankPage);
          this.scrollToTop();
        }
      });
    } else {
      this.openBlankPageWithQueryParams(null, favouriteChapter);
    }
    if (this.bibleCompare.includes(this.bible)) {
      this.form.get('bibleCompare').setValue([]);
      this.compareChapterItems = undefined;
    } else {
      this.getCompareChapters();
    }
  }

  private updateFormByFavouriteChapter(favouriteChapter: FavouriteChapter): void {
    this.form.get('bible').setValue(favouriteChapter.bible);
    this.form.get('book').setValue(favouriteChapter.book);
    this.form.get('chapter').setValue(favouriteChapter.chapter);
    this.updateChapters();
  }

  private navigateToCurrentChapter(favouriteChapter: FavouriteChapter, openInBlankPage = false): void {
    this.omitDataUpdateOnQueryParamsChange = true;
    this.updateQueryParams(null, openInBlankPage);
    this.scrollToVerse(favouriteChapter.verse?.toString());
  }

  private scrollToVerse(verse: string): void {
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor(verse);

      const element = document.getElementById(verse)?.parentElement;

      if (!element) {
        return;
      }
      element.classList.add('active-transition');
      setTimeout(() => element.classList.add('active'), 600);
      setTimeout(() => {
        element.classList.remove('active');
        element.classList.remove('active-transition');
      }, 1600);
    }, 500);
  }

  leftPanelCopyChapter(favouriteChapter: FavouriteChapter): void {
    if (this.chapterItem.chapter === favouriteChapter.chapter) {
      if (favouriteChapter.verse) {
        this.copyVerse(this.chapterItem.verses[favouriteChapter.verse - 1]);
      } else {
        this.copyChapter(this.chapterItem.verses);
      }
    } else {
      this.getChapter(this.bible, this.book, favouriteChapter.chapter)
        .subscribe(chapterItem => {
          if (favouriteChapter.verse) {
            this.copyVerse(chapterItem.verses[favouriteChapter.verse - 1]);
          } else {
            this.copyChapter(chapterItem.verses)
          }
        });
    }
  }

  leftPanelCopyChapterWithNumbers(favouriteChapter: FavouriteChapter): void {
    if (this.chapterItem.chapter === favouriteChapter.chapter) {
      this.copyChapterWithNumbers(this.chapterItem.verses);
    } else {
      this.getChapter(this.bible, this.book, favouriteChapter.chapter)
        .subscribe(chapterItem => this.copyChapterWithNumbers(chapterItem.verses));
    }
  }

  private getChapter(code: BibliaInfoCode | CustomBibleCode, book: BibliaInfoBook, chapter: number, clearUbg = true): Observable<BibliaInfoChapterModel> {
    if (code === CustomBibleCode.UBG_STRONGA) {
      if (BOOKS_NAMES_2.has(book)) {
        return this.strongService.getUbgChapter(book, chapter).pipe(
          map(ubgChapter => {
            this.versesUbg = ubgChapter.book[0].text;
            return getUbgBibliaInfoChapter(book, chapter, ubgChapter.book[0].text);
          })
        )
      } else {
        this.versesUbg = null;
        code = BibliaInfoCode.UWSPOLCZESNIONA_BIBLIA_GDANSKA;
        return this.bibliaInfo.getChapter(code, book, chapter);
      }
    }
    if (clearUbg) {
      this.versesUbg = null;
    }
    return this.bibliaInfo.getChapter(code as BibliaInfoCode, book, chapter);
  }

  compareVerse(item: FavouriteChapter): void {
    const bookGroup = BOOKS_NAMES.get(item.book as BibliaInfoBook)?.group;
    const books = AVAILABLE_BIBLES.get(bookGroup).map(bible => this.bibliaInfo
      .getVerses(bible as BibliaInfoCode, item.book as BibliaInfoBook, item.chapter, item.verse.toString())
      .pipe(catchError(() => of(null)))
    );

    this.fetchCompareVerses(books);
  }

  copyVerses(): void {
    this.dialog.open<CopyVersesDialogComponent, CopyVersesDialogData>(CopyVersesDialogComponent, {
      data: {
        book: this.chapterItem.book,
        chapter: this.chapterItem.chapter,
        max: this.chapterItem.verses.length,
      },
      panelClass: 'reading'
    }).afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }
        const { from, to, keepVerses } = result;
        if (from > 0) {
          if (!keepVerses || from === to) {
            this.clipboard.copy(BibleComponent.getChapterStr(this.chapterItem.verses.slice(from - 1, to)));
          } else {
            this.clipboard.copy(BibleComponent.getChapterStrWithNumber(this.chapterItem.verses.slice(from - 1, to)));
          }
          this.snackbarService.open(`Skopiowano wersety ${from} - ${to}`, SnackBarType.COPY, 2000);
        }
      });
  }

  fullScreenMode(value: boolean): void {
    this.fullScreen = value;
    this.updatePanelsBasedOnScreenMode(value);
  }

  openToolsDialog(): void {
    this.dialog.open<
      BibleFavouritesMobileDialogComponent,
      BibleFavouritesMobileDialogData,
      BibleFavouritesMobileDialogResult
    >(BibleFavouritesMobileDialogComponent, {
      panelClass: 'reading', minWidth: '100vw', data: {
        favouriteChapters: this.favouriteChapters,
        books: this.books,
        lastChapters: this.lastChapters,
      }
    }).afterClosed().subscribe((result: BibleFavouritesMobileDialogResult) => {
      switch (result?.type) {
        case BibleFavouritesMobileDialogResultType.selectChapter: {
          this.leftPanelSelectChapter(result.data);
          break;
        }
        case BibleFavouritesMobileDialogResultType.selectVerse: {
          this.leftPanelSelectChapter(result.data, true);
          break;
        }
        case BibleFavouritesMobileDialogResultType.copyChapter: {
          this.leftPanelCopyChapter(result.data as FavouriteChapter);
          break;
        }
        case BibleFavouritesMobileDialogResultType.copyChapterWithNumbers: {
          this.leftPanelCopyChapterWithNumbers(result.data as FavouriteChapter);
          break;
        }
        case BibleFavouritesMobileDialogResultType.compareVerse: {
          this.compareVerse(result.data as FavouriteChapter);
          break;
        }
      }
    })
  }

  openBibleBookDialog(): void {
    this.openBibleBookDialogRef = this.dialog.open<
      BibleBookInfoDialogComponent,
      BibleBookInfoDialogData,
      BibleBookInfoDialogResult
    >(BibleBookInfoDialogComponent, {
      panelClass: 'reading', minWidth: '80vw', data: {
        book: this.book, chapter: this.chapter
      }
    });

    this.openBibleBookDialogRef.afterClosed().subscribe((result: BibleBookInfoDialogResult) => {
      if (!result) {
        return;
      }
      this.isLoading = true;
      this.collapseFilterExpansionPanelOnMobile();
      this.form.get('book').setValue(result.book);
      this.form.get('chapter').setValue(result.chapter);
      this.updateInvalidBible();
      this.updateQueryParams();
      this.fetchBook();
    });
  }

  openSelectTranslationsDialog(): void {
    this.dialog.open<SelectBibleTranslationsDialogComponent, SelectBibleTranslationsDialogData>(SelectBibleTranslationsDialogComponent, {
      panelClass: 'reading', minWidth: '80vw', data: {
        translations: this.bibles,
        selected: this.selectedTranslations?.map(item => item.abbreviation),
        current: this.bible,
        compareCurrent: this.bibleCompare
      }
    }).afterClosed().subscribe((selectedTranslations: string[]) => {
      if (!selectedTranslations?.length) {
        return;
      }
      StateService.setArray<string>('selected-bible-translations', selectedTranslations);
      this.selectedTranslations = selectedTranslations.length > 0
        ? this.bibles.filter(bible => selectedTranslations.includes(bible.abbreviation))
        : this.bibles;
      this.divideBibleGroups(this.selectedTranslations);
    });
  }

  onMaxCompareColumnsChange(maxCompareColumns: number): void {
    this.maxCompareColumns = maxCompareColumns;
    if (this.compareChapterItems) {
      const compareChapterItems = new Map<BibliaInfoCode, BibliaInfoChapterModel>();
      this.compareChapterItems.forEach((value, code) => {
        if (compareChapterItems.size < maxCompareColumns) {
          compareChapterItems.set(code, value);
        }
      });
      setTimeout(() => {
        this.compareChapterItems = compareChapterItems;
        this.cdr.markForCheck();
      }, 800);
    }
  }

  private updateInvalidBible(): void {
    if (BOOKS_NAMES_3.has(this.book)) {
      if (![BibliaInfoCode.BIBLIA_TYSIACLECIA, BibliaInfoCode.POZNANSKA, BibliaInfoCode.VULGATA,].includes(this.bible)) {
        this.form.get('bible').setValue(BibliaInfoCode.BIBLIA_TYSIACLECIA);
        this.openNoBookInTranslationWarning();
      }
    }
    if (BOOKS_NAMES_1.has(this.book)) {
      if ([BibliaInfoCode.SLOWO_ZYCIA, BibliaInfoCode.PRZEKLAD_DOSLOWNY, BibliaInfoCode.BIBLIA_TORUNSKA,].includes(this.bible)) {
        this.form.get('bible').setValue(BibliaInfoCode.UWSPOLCZESNIONA_BIBLIA_GDANSKA);
        this.openNoBookInTranslationWarning();
      }
    }
  }

  private openNoBookInTranslationWarning(): void {
    this.snackbarService.open('Podana księga nie występuje w wybranym tłumaczeniu', SnackBarType.WARNING);
  }

  private updateInvalidChapter(): void {
    if (this.chapter > BOOKS_CHAPTERS.get(this.book)) {
      this.form.get('chapter').setValue(1);
    }
  }

  private collapseFilterExpansionPanelOnMobile(): void {
      this.bibleFilterComponent?.expansionPanel?.close();
  }

  private updatePanelsBasedOnScreenMode(value: boolean): void {
    if (value) {
      this.hideLeftPanel = true;
      this.hideRightPanel = true;
    } else {
      this.hideLeftPanel = !this.stateService.bibleShowLeftPanel;
      this.hideRightPanel = !this.stateService.bibleShowRightPanel;
    }
  }

  private copyVerse(verse: BibliaInfoVerseModel): void {
    this.clipboard.copy(verse.text);
    this.snackbarService.open('Skopiowano werset', SnackBarType.COPY, 2000);
  }

  private copyChapter(verses: BibliaInfoVerseModel[]): void {
    this.clipboard.copy(BibleComponent.getChapterStr(verses));
    this.snackbarService.open('Skopiowano rozdział', SnackBarType.COPY, 2000);
  }

  private copyChapterWithNumbers(verses: BibliaInfoVerseModel[]): void {
    this.clipboard.copy(BibleComponent.getChapterStrWithNumber(verses));
    this.snackbarService.open('Skopiowano rozdział', SnackBarType.COPY, 2000);
  }

  private fetchCompareVerses(observables: Observable<BibliaInfoChapterModel>[]): void {
    forkJoin(observables).pipe(
      tap(() => {
        this.isLoading = true;
        this.cdr.markForCheck();
      }),
    ).subscribe({
      next: (bibles) => this.onCompareVerseSuccess(bibles),
      error: () => this.onCompareVerseError(),
    });
  }

  private onCompareVerseSuccess(bibles: BibliaInfoChapterModel[]): void {
    this.openCompareDialog(bibles);
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  private onCompareVerseError(): void {
    this.snackbarService.open('Wystąpił błąd przy pobieraniu przekładów', SnackBarType.ERROR, 4000);
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  private openCompareDialog(bibles: BibliaInfoChapterModel[]): void {
    this.dialog.open(CompareVerseDialogComponent, { data: bibles, panelClass: 'reading' })
      .afterClosed().subscribe(() => this.favouriteChapters = this.leftPanelService.favouriteChapters);
  }

  static getChapterStr(verses: BibliaInfoVerseModel[]): string {
    return verses.reduce<string>((prev, curr) => `${prev} ${curr.text}`, '').substring(1);
  }

  static getChapterStrWithNumber(verses: BibliaInfoVerseModel[]): string {
    return verses.reduce<string>((prev, curr) => `${prev} (${curr.verse}) ${curr.text}`, '').substring(1);
  }

  private initFormFromStorage(): void {
    const bibleFromStorage: BibleStorage = JSON.parse(localStorage.getItem('bible'));
    this.form = this.fb.group({
      bible: this.fb.control(bibleFromStorage?.bible || BibliaInfoCode.UWSPOLCZESNIONA_BIBLIA_GDANSKA),
      book: this.fb.control(bibleFromStorage?.book || BibliaInfoBook.EWANGELIA_MATEUSZA),
      chapter: this.fb.control(bibleFromStorage?.chapter || 1),
      bibleCompare: this.fb.control(bibleFromStorage?.bibleCompare || []),
    });
    this.updatePrevForm();
  }

  private observeQueryParams(): void {
    this.subscription.add(this.activatedRoute.queryParams.subscribe((params: BibleQueryParams) => {

      if (this.skipFetchingDataAndScrollToVerse) {
        this.scrollToVerse(params.werset + '');
        this.skipFetchingDataAndScrollToVerse = false;
        return;
      }
      if (!this.omitDataUpdateOnQueryParamsChange) {
        const { ksiega, rozdzial, przeklad, werset } = params;

        if (ksiega && rozdzial) {
          this.updateBookAndChapterUsingParams(ksiega as BibliaInfoBook, +rozdzial);
        } else if (ksiega) {
          this.updateBookUsingParams(ksiega);
        }
        if (przeklad) {
          this.updateBibleUsingParams(przeklad as BibliaInfoCode)
        }

        this.updateQueryParams();
        this.fetchTranslations();
        this.fetchTranslation();
        this.fetchBook(werset);
      } else {
        this.omitDataUpdateOnQueryParamsChange = false;
      }
    }));
  }

  private updateBibleUsingParams(code: BibliaInfoCode): void {
    if (AVAILABLE_BIBLES_CODES.includes(code)) {
      this.form.get('bible').setValue(code);

      if (this.bibleCompare.includes(code)) {
        this.form.get('bibleCompare').setValue([]);
      }
    }
  }

  private updateBookAndChapterUsingParams(book: BibliaInfoBook, chapter: number): void {
    const bookGroup = BOOKS_NAMES.get(book)?.group;
    const validChapter = Number.isInteger(chapter) && chapter > 0 && chapter <= BOOKS_CHAPTERS.get(book);

    if (bookGroup && validChapter) {
      this.form.get('book').setValue(book);
      this.form.get('chapter').setValue(chapter);
    }
  }

  private updateBookUsingParams(book: BibliaInfoBook): void {
    const bookGroup = BOOKS_NAMES.get(book)?.group
    if (bookGroup) {
      this.form.get('book').setValue(book);
    }
  }

  private fetchTranslations(): void {
    this.bibliaInfo.getTranslations().subscribe(translations => {
      this.bibles = translations.bibles;
      const selectedBibles = StateService.getArray('selected-bible-translations');
      this.selectedTranslations = selectedBibles?.length > 0
        ? translations.bibles.filter(bible => selectedBibles.includes(bible.abbreviation))
        : translations.bibles;
      this.divideBibleGroups(this.selectedTranslations);
    });
  }

  private divideBibleGroups(translations: BibliaInfoBibleModel[]): void {
    let translationsByLang = BibleComponent.groupBy<BibleTranslation>(translations, 'language');
    translationsByLang = this.getCustomTranslations(translationsByLang);

    this.bibleGroup = Object.entries(translationsByLang).map(([key, options]) => {
      return {
        name: BibleComponent.bibleLanguageNames.get(key),
        options: options.map(({ abbreviation, name }) => ({ abbreviation, name })),
      };
    });
    this.cdr.markForCheck();
  }

  private getCustomTranslations(translations: { [key: string]: BibleTranslation[] }): { [key: string]: BibleTranslation[] } {
    const ugIndex = translations.pl.findIndex(item => BibliaInfoCode.UWSPOLCZESNIONA_BIBLIA_GDANSKA === item.abbreviation);
    translations.pl.splice(ugIndex + 1, 0, ubgTranslation);
    return translations;
  }

  private fetchTranslation(): void {
    this.bibliaInfo.getTranslation(this.bible).pipe(
      catchError(err => {
        if (err.status === 404) {
          this.handle404ApiError();
        }
        return EMPTY;
      })
    ).subscribe(translation => {
      this.description = translation.description;
      const booksItems: BibleItem[] = BibleComponent.getBooksItemsFromModel(translation);

      const booksByPart = BibleComponent.groupBy<BibleItem>(booksItems, 'part');
      this.bookGroup = BibleComponent.getBookGroup(booksByPart, BibleComponent.bookPartNames);

      if (!this.isBookInAvailableBooks(booksItems)) {
        return this.clearBookInput();
      }

      if (this.book && this.chapterItem) {
        this.fetchChapter(false).subscribe();
      } else {
        this.form.get('book').enable();
        this.updateFavouriteChapter();
        this.isLoading = false;
        this.cdr.markForCheck();
      }
    });
  }

  static getBooksItemsFromModel(model: BibliaInfoTranslationModel): BibleItem[] {
    return model.books.map(({abbreviation, name, part: { abbreviation: partName }}) => {
      return { abbreviation, name, part: partName }
    });
  }

  private isBookInAvailableBooks(books: BibleItem[]): boolean {
    return books.some(item => item.abbreviation === this.book);
  }

  private handle404ApiError(): void {
    this.snackbarService.open('Nie znaleziono podanej księgi na serwerze', SnackBarType.ERROR);
    this.form.get('bible').setValue(BibliaInfoCode.UWSPOLCZESNIONA_BIBLIA_GDANSKA);
    this.form.get('book').setValue(BibliaInfoBook.EWANGELIA_MATEUSZA);
    this.form.get('chapter').setValue(1);
    this.form.get('bibleCompare').setValue([]);
    this.fetchTranslation();
    this.fetchBook();
  }

  private clearBookInput(): void {
    this.openNoBookInTranslationWarning();
    this.form.get('book').setValue(null);
    this.form.get('chapter').setValue(null);
    this.form.get('chapter').disable();
    this.form.get('bibleCompare').disable();
    this.hideNextChapterButton = true;
    this.hidePrevChapterButton = true;
    this.chapters = undefined;
    this.chapterItem = undefined;
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  static getBookGroup(booksByPart: { [key: string]: BibleItem[] }, bookPartNames: Map<string, string>): BibleGroup[] {
    return Object.entries(booksByPart).map(([key, options]) => {
      return {
        name: bookPartNames.get(key),
        options: options.map(({ abbreviation, name }) => ({ abbreviation, name })),
      };
    }).sort((a: BibleGroup, b: BibleGroup) => BibleComponent.sortByMapIndex(a, b, bookPartNames));
  }

  private fetchBook(verse?: number): void {
    this.updateChapters();
    this.omitDataUpdateOnQueryParamsChange = true;

    this.bibliaInfo.getBook(this.bible, this.book).subscribe(bookItem => {
      const isChapterAvailable = (this.chapter === 1 || this.chapter && this.chapter <= bookItem.chapters);

      if (isChapterAvailable) {
        this.form.get('chapter').enable();
        this.fetchChapter(false, verse).subscribe(() => this.scrollToTop());
        this.getCompareChapters();
      } else {
        if (this.chapter) {
          this.snackbarService.open('Podany rozdział nie występuje w wybranej księdze', SnackBarType.WARNING);
        }
        this.form.get('chapter').setValue(null);
        this.form.get('chapter').enable();
        this.hidePrevChapterButton = true;
        this.hideNextChapterButton = true;
        this.chapterItem = undefined;
        this.compareChapterItems = undefined;
        this.updateFavouriteChapter();
        this.isLoading = false;
        this.cdr.markForCheck();
      }
    });
  }

  private updateChapters(): void {
    const chapters = BOOKS_CHAPTERS.get(this.book);
    this.chapters = Array.from({ length: (chapters === 0) ? 1 : chapters }, (_, i) => i + 1);
  }

  private fetchChapter(collapseFilter = true, verse?: number): Observable<BibliaInfoChapterModel> {
    return this.getChapter(this.bible, this.book, this.chapter).pipe(tap(chapterItem => {
      this.saveBibleInStorage();
      this.updatePrevForm();
      this.updateButtonVisibility();
      this.form.get('bibleCompare').enable();
      this.chapterItem = chapterItem;
      this.updateFavouriteChapter();
      this.leftPanelService.setLastChapter(this.getFavouriteChapter());
      if (collapseFilter) {
        this.collapseFilterExpansionPanelOnMobile();
      }

      if (verse && verse >= 0) {
        const favouriteChapter = this.getFavouriteChapter();
        favouriteChapter.verse = verse;
        this.navigateToCurrentChapter(favouriteChapter);
      }

      this.isLoading = false;
      this.cdr.markForCheck();
    }));
  }

  private getCompareChapters(): void {
    if (this.bibleCompare?.length > 0) {
      this.isLoading = true;
      this.cdr.markForCheck();
      this.saveBibleInStorage();
      this.fetchCompareChapters(this.bibleCompare);
    } else {
      this.saveBibleInStorage();
      this.fetchCompareChapters(this.bibleCompare);
    }
  }

  private fetchCompareChapters(codes: BibliaInfoCode[]): void {
    if (codes.length === 0) {
      return this.clearCompareChapters();
    }
    const observables = codes.map(code => this.getChapter(code as BibliaInfoCode, this.book, this.chapter, false));
    forkJoin(observables).subscribe({
      next: (items) => {
        this.compareChapterItems = new Map<BibliaInfoCode, BibliaInfoChapterModel>();
        codes.forEach((code, i) => this.compareChapterItems.set(code, items[i]));
        this.isLoading = false;
        this.cdr.markForCheck();
      },
      error: res => this.compareChapterErrorHandler(res),
    });
  }

  private clearCompareChapters(): void {
    this.compareChapterItems = null;
    this.form.get('bibleCompare').setValue([]);
    this.cdr.markForCheck();
  }

  private compareChapterErrorHandler(res: HttpErrorResponse): void {
    const error: BibliaInfoErrorModel = res.error;
    this.form?.get('bibleCompare').setValue(this.prevFormState.get('bibleCompare').value);
    this.snackbarService.open(error.text);
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  static groupBy<T>(arr: any[], property: string): { [key: string]: T[]; } {
    return arr.reduce((memo, x) => {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {}) as { [key: string]: T[]; };
  }

  private static sortByMapIndex(a: BibleGroup, b: BibleGroup, map: Map<string, string>): number {
    const array = Array.from( map.values());
    return array.indexOf(a.name) - array.indexOf(b.name);
  }

  private saveBibleInStorage(): void {
    const object: BibleStorage = {
      bible: this.bible,
      book: this.book,
      chapter: this.chapter,
      bibleCompare: this.bibleCompare,
    }
    localStorage.setItem('bible', JSON.stringify(object));
  }

  private observeBreakpointsChanges(): void {
    this.subscription.add(this.breakpointObserver.observe(['(min-width: 1700px)'])
      .subscribe(value => {
        this.over1700px = value.matches;
        this.collapseAsideFilter = !this.over1700px || this.menuService.isOpen;
      }));
  }

  private observeToggle(): void {
    this.subscription.add(this.menuService.toggle$.subscribe(() => {
      this.collapseAsideFilter = !this.over1700px || this.menuService.isOpen;
      this.isSideNavOpen = this.menuService.isOpen;
      this.cdr.markForCheck();
    }));
  }

  private scrollToTop(): void {
    this.viewportScroller.scrollToPosition([0,0]);
  }

  private updateForm(form: FormGroup<BibleForm>): void {
    this.form.setValue({
      bible: form.get('bible').value,
      book: form.get('book').value,
      chapter: form.get('chapter').value,
      bibleCompare: form.get('bibleCompare').value,
    });
    this.updateQueryParams();
  }

  private updatePrevForm(): void {
    this.prevFormState = this.fb.group<BibleForm>({
      bible: this.fb.control(this.bible),
      book: this.fb.control(this.book),
      chapter: this.fb.control(this.chapter),
      bibleCompare: this.fb.control([this.bibleCompare[0]]),
    });
  }

  private updateQueryParams(verse?: number, openInBlankPage = false): void {
    if (openInBlankPage) {
      this.openBlankPageWithQueryParams(verse);
    } else {
      this.mergeQueryParams(verse);
    }
  }

  private mergeQueryParams(verse?: number): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          przeklad: this.bible,
          ksiega: this.book,
          rozdzial: this.chapter,
          werset: verse
        },
        queryParamsHandling: 'merge'
      });
  }

  private openBlankPageWithQueryParams(verse?: number, favouriteChapter?: FavouriteChapter): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['biblia/przeklady'], {
        queryParams: {
          przeklad: favouriteChapter.bible || this.bible,
          ksiega: favouriteChapter.book || this.book,
          rozdzial: favouriteChapter.chapter || this.chapter,
          werset: verse
        }
      }));
    window.open(url, '_blank');
  }

  private updateButtonVisibility(): void {
    this.hidePrevChapterButton = this.chapter === 1;
    this.hideNextChapterButton = this.chapter === this.chapters.length;
  }

  private observeShowLeftPanel(): void {
    this.subscription.add(this.stateService.bibleShowLeftPanel$.subscribe(value => this.hideLeftPanel = !value));
  }

  private observeShowRightPanel(): void {
    this.subscription.add(this.stateService.bibleShowRightPanel$.subscribe(value => this.hideRightPanel = !value));
  }

  private observeScreenMode(): void {
    this.subscription.add(this.stateService.bibleScreenMode$.subscribe(value => {
      if (value) {
        this.hideLeftPanel = true;
        this.hideRightPanel = true;
      } else {
        this.hideLeftPanel = !this.stateService.bibleShowLeftPanel;
        this.hideRightPanel = !this.stateService.bibleShowRightPanel;
      }
    }));
  }

  private observeFavouriteChapter(): void {
    this.subscription.add(this.leftPanelService.favouriteChapters$.subscribe(() => {
      this.updateFavouriteChapter();
      this.favouriteChapters = this.leftPanelService.favouriteChapters;
    }));
  }

  private observeLastChapter(): void {
    this.subscription.add(this.leftPanelService.lastChapters$.subscribe(() => {
      this.lastChapters = this.leftPanelService.lastChapters;
    }));
  }

  private updateFavouriteChapter(): void {
    this.isFavouriteChapter = this.findChapterInFavourites(this.leftPanelService.favouriteChapters);
  }

  private getFavouriteChapter(): FavouriteChapter {
    return {
      bible: this.bible,
      book: this.book,
      chapter: this.chapter,
    };
  }

  private isCurrentChapter(item: FavouriteChapter): boolean {
    return this.bible === item.bible && this.book === item.book && this.chapter === item.chapter;
  }

  private findChapterInFavourites(chapters: FavouriteChapter[]): boolean {
    return !!chapters.find(el => {
      return el && el.bible === this.bible && el.book === this.book && el.chapter === this.chapter && el.verse === undefined;
    });
  }

  private fetchBooks(): void {
    this.bibliaInfo.getTranslation(BibliaInfoCode.BIBLIA_TYSIACLECIA).subscribe(translation => this.books = translation.books);
  }

  private validateCompareBible(bible: BibliaInfoCode): void {
    if (this.bibleCompare.includes(bible)) {
      this.form.get('bibleCompare').setValue([]);
      this.compareChapterItems = undefined;
    }
  }

  private observeVersesList(): void {
    this.subscription.add(
      this.versesListDialogService.currentList$.subscribe(() => {
        this.updateFavouriteChapter();
        this.favouriteChapters = this.leftPanelService.favouriteChapters;
      })
    );
  }

  static isSelectChapterEvent(object: any): object is SelectChapterEvent {
    return 'openInBlankPage' in object && 'selectChapter' in object;
  }

  protected bibleSearchQuery(result: BibleQueryResult) {
    if (result.chapter) {
      this.form.controls.chapter.setValue(result.chapter);
    }
    if (result.book !== this.book) {
      this.form.controls.book.setValue(result.book);
    }
    this.bookSelectionChange(this.form, result.verse);
  }
}
